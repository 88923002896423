<template>
  <b-card style="width: 100%">
    <b-row>
      <!-- User Info: Left col -->
      <b-col class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h2 class="font-weight-bold">
                Project name: {{ projectData.name }}
              </h2>
              <h4 class="mt-2 font-weight-bold">
                Comment: {{ projectData.comment }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{
                  name: 'apps-users-edit',
                  params: { id: projectData.id },
                }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="showModal(projectData.id)"
              >
                Delete
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Owner</span>
            </th>
            <td class="pb-50">
              {{ profileData.first_name }} {{ profileData.last_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">admin</td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">unpublished</td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="CalendarIcon" class="mr-75" />
              <span class="font-weight-bold">Create date</span>
            </th>
            <td>
              {{ projectData.create_date }}
            </td>
          </tr>
        </table>
      </b-col>

      <b-modal
        id="modal-1"
        size="sm"
        centered
        ok-title="Remove"
        ok-variant="danger"
        title="Are you sure?"
        @ok="sendRequestToRemoveProject"
      >
        <div class="d-block">Do you want to remove this project?</div>
      </b-modal>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BRow, BCol } from "bootstrap-vue";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedId: null,
  }),
  computed: {
    profileData() {
      return this.$store.state.Profile.profileData;
    },
  },
  methods: {
    sendRequestToRemoveProject() {
      store
        .dispatch("Project/deleteProject", {
          projectId: this.selectedId,
        })
        .then(() => {
          this.selectedId = null;
          this.$router.push("/projects");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "You have successfully remove this project",
              icon: "TrashIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showModal(id) {
      this.selectedId = id;
      this.$root.$emit("bv::show::modal", "modal-1", "#btnShow");
    },
  },
};
</script>

<style>
</style>
