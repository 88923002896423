<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="projectData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="projectData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <user-view-user-info-card :project-data="projectData" />
        </b-col>
      </b-row>
      <invoice-list :project-data="projectData" />
    </template>
  </div>
</template>

<script>
import store from "@/store";
import { ref } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
import InvoiceList from "@/views/apps/invoice/invoice-list/InvoiceList.vue";
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    InvoiceList,
  },
  setup() {
    const projectData = ref(null);

    store
      .dispatch("Project/loadProject", store.state.Project.project.id)
      .then((response) => {
        projectData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          projectData.value = undefined;
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "Please try again",
          },
        });
      });

    return {
      projectData,
    };
  },
};
</script>

<style>
</style>
