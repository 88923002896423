<template>
  <div>
    <b-card no-body>
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <!--            <b-button-->
            <!--              variant="primary"-->
            <!--              @click="sendRequestToCreateNewEditor"-->
            <!--            >-->
            <!--              Create new Editor-->
            <!--            </b-button>-->
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refInvoiceListTable"
          :items="fetchEditor"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
              class="font-weight-bold"
          >
            #{{ data.version }}
          </b-link>
        </template>

        <!-- Column: Issued development -->
        <template #cell(development)="data">
          <div style="display: flex; justify-content: center">
            <b-form-checkbox
                @change="sendRequestToUpdateStatus(data.item, 'development')"
                v-model="data.item.development"
            >
            </b-form-checkbox>
          </div>
        </template>

        <!-- Column: Issued staging -->
        <template #cell(staging)="data">
          <div style="display: flex; justify-content: center">
            <b-form-checkbox
                @change="sendRequestToUpdateStatus(data.item, 'staging')"
                v-model="data.item.staging"
            >
            </b-form-checkbox>
          </div>
        </template>

        <!-- Column: Issued production -->
        <template #cell(production)="data">
          <div style="display: flex; justify-content: center">
            <b-form-checkbox
                @change="sendRequestToUpdateStatus(data.item, 'production')"
                v-model="data.item.production"
            >
            </b-form-checkbox>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap" style="cursor: pointer">
            <!--            <feather-icon-->
            <!--              :id="`invoice-row-${data.item.id}-preview-icon`"-->
            <!--              icon="EyeIcon"-->
            <!--              size="16"-->
            <!--              class="mx-1"-->
            <!--              @click.prevent="goToEditor(data.item.id)"-->
            <!--            />-->
            <!--            <b-tooltip-->
            <!--              title="Preview Invoice"-->
            <!--              :target="`invoice-row-${data.item.id}-preview-icon`"-->
            <!--            />-->

            <feather-icon
                :id="`invoice-row-${data.item.id}-send-icon`"
                icon="TrashIcon"
                class="cursor-pointer"
                size="16"
                @click="showModal(data.item.id)"
            />
            <b-tooltip
                title="Remove Editor"
                class="cursor-pointer"
                :target="`invoice-row-${data.item.id}-send-icon`"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{
                dataMeta.of
              }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalInvoices"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
        id="modal-2"
        size="sm"
        centered ok-title="Remove"
        ok-variant="danger"
        title="Are you sure?"
        @ok="sendRequestToRemoveEditor">
      <div class="d-block">Do you want to remove this editor?</div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,  BTable, BLink, BPagination, BTooltip, BFormCheckbox,
} from 'bootstrap-vue'
import {avatarText} from '@core/utils/filter'
import vSelect from 'vue-select'
import store from '@/store'
import useInvoicesList from './useInvoiceList'
import ToastificationContent from "@core/components/toastification/ToastificationContent"

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput, 
    BTable,
    BLink,
    BPagination,
    BTooltip,
    BFormCheckbox,

    vSelect,
  },
  setup() {
    const {
      fetchEditor,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      listEditors,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      fetchEditor,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      listEditors,

      statusFilter,

      refetchData,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
  data: () => ({
    selectedEditorId: null,
  }),
  props: {
    projectData: {}
  },
  methods: {
    async sendRequestToCreateNewEditor() {
      await store.dispatch('Editor/setNewEditor', {
        version: '1',
        projectId: this.projectData.id,
        json_file: '{ "test": "test" }',
        staging: false,
        production: false,
        current: false,
      })
          .then(() => {
            store.dispatch('Projects/GET_MY_PROJECTS')
          })
    },

    sendRequestToRemoveEditor() {
      store.dispatch('Editor/deleteEditor', {editorId: this.selectedEditorId, projectId: this.projectData.id})
          .then(() => {
            this.selectedId = null
            this.hideModal()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'You have successfully remove this editor',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: err.response.data?.detail ? err.response.data?.detail : 'Please try again'
              },
            })
          })
    },

    sendRequestToUpdateStatus(editor, value) {
      store.dispatch('Editor/updateEditorStatus', {
        development: editor.development,
        staging: editor.staging,
        production: editor.production,
        editorId: editor.id,
      })
          .then(() => {
            this.hideModal()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'You have successfully update this editor',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
          })
          .catch(err => {
            setTimeout(() => {
              let currentEditorIndex = this.listEditors.findIndex(x => x.id === editor.id)
              switch (value) {
                case 'development':
                  this.listEditors[currentEditorIndex].development = !editor.development
                  break
                case 'staging':
                  this.listEditors[currentEditorIndex].staging = !editor.staging
                  break
                case 'production':
                  this.listEditors[currentEditorIndex].production = !editor.production
                  break
              }
            }, 500)


            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: err.response.data?.environment ? err.response.data?.environment : 'Please try again'
              },
            })
          })
    },

    showModal(id) {
      this.selectedEditorId = id
      this.$root.$emit('bv::show::modal', 'modal-2', '#btnShow')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
